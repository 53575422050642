import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { ThemeContext } from "../context/ThemeContext";

const Footer = () => {
	const { theme } = useContext(ThemeContext);
	const { language } = useContext(LanguageContext);

	return (
		<div
			className={
				(theme === "light" ? "text-black " : "text-white ") +
				"w-full flex flex-col items-center justify-center mt-[30px] pb-[15px] z-40"
			}
		>
			<span className="w-4/6 flex justify-around">
				<Link to="/mentions-légales" className="font-title text-xs z-40">
					{language === "french" ? "Mentions Légales" : "Legal Notice"}
				</Link>
				<a
					href="ma&#105;lto&#58;%&#54;&#68;&#97;x&#37;&#54;9%6De&#46;g&#105;b&#105;&#101;&#114;%40ic%6&#67;o&#117;d%2&#69;%6&#51;&#111;m"
					className="font-title text-xs z-40"
				>
					{language === "french" ? "Me contacter" : "contact me"}
				</a>
			</span>
			<p className="font-text text-sm pt-[10px]">
				{language === "french"
					? "Développé avec React et TailwindCSS"
					: "Developped with React and TailwindCSS"}
			</p>
		</div>
	);
};

export default Footer;
