import React, { useContext } from "react";
import { FiGithub, FiMail } from "react-icons/fi";
import { BsArrowUp } from "react-icons/bs";
import { ThemeContext } from "../context/ThemeContext";
import { Link } from "react-router-dom";

const Socials = () => {
	const { theme } = useContext(ThemeContext);

	return (
		<div>
			<div
				className={
					(theme === "light" ? "text-black " : "text-white ") +
					"fixed left-0 h-screen hidden tablet:flex flex-col justify-between items-center z-20 pl-8 mt-6"
				}
			>
				<Link to="/" href="https://www.maxime-gibier.fr" className="z-30">
					<h2 className="font-title">Maxime</h2>
				</Link>
				<ul className="flex flex-col justify-around h-2/5">
					<li className="">
						<a
							className="flex justify-between items-center w-full font-title"
							href="https://www.linkedin.com/in/maxime-gibier/"
							target="_blank"
							rel="noopener noreferrer"
						>
							IN.
						</a>
					</li>
					<li className="">
						<a
							className="flex justify-between items-center w-full"
							href="https://github.com/Maxime-Gibier"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FiGithub size={30} />
						</a>
					</li>
					<li className="">
						<a
							className="flex justify-between items-center w-full"
							href="ma&#105;lto&#58;%&#54;&#68;&#97;x&#37;&#54;9%6De&#46;g&#105;b&#105;&#101;&#114;%40ic%6&#67;o&#117;d%2&#69;%6&#51;&#111;m"
						>
							<FiMail size={30} />
						</a>
					</li>
				</ul>
				<a className="h-1/6 flex items-center justify-end" href="#top">
					<BsArrowUp />
				</a>
			</div>
		</div>
	);
};

export default Socials;
