import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

function ThemeProvider({ children }) {
	const [theme, setTheme] = useState(localStorage.getItem("theme"));

	useEffect(() => {
		const html = document.getElementById("html");
		html.classList.remove(theme === "dark" ? "bg-bgd-light" : "bg-bgd-dark");
		html.classList.add(theme === "light" ? "bg-bgd-light" : "bg-bgd-dark");
		localStorage.setItem("theme", theme);
	}, [theme]);

	const toggleTheme = () => {
		setTheme(theme === "light" ? "dark" : "light");
	};

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
}

export default ThemeProvider;
