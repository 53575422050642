import React, { useContext } from "react";
import {
	TbBrandHtml5,
	TbBrandJavascript,
	TbBrandCss3,
	TbBrandReactNative,
	TbBrandPhp,
	TbBrandFigma,
	TbBrandPython,
	TbBrandWebflow,
} from "react-icons/tb";
import { IoLogoNodejs } from "react-icons/io";
import { FaWordpressSimple } from "react-icons/fa";
import { ThemeContext } from "../context/ThemeContext";
import { LanguageContext } from "../context/LanguageContext";
import Icon from "./Icon";

const Skills = () => {
	const { theme } = useContext(ThemeContext);
	const { language } = useContext(LanguageContext);

	const skills = [
		{
			title: "Front-End",
			front: [
				{
					name: "html",
					icon: TbBrandHtml5,
				},
				{
					name: "css",
					icon: TbBrandCss3,
				},
				{
					name: "JS",
					icon: TbBrandJavascript,
				},
				{
					name: "react",
					icon: TbBrandReactNative,
				},
			],
		},
		{
			title: "Back-End",
			front: [
				{ name: "NodeJS", icon: IoLogoNodejs },
				{
					name: "PHP",
					icon: TbBrandPhp,
				},
			],
		},
		{
			title: language === "french" ? "Autre langages" : "Other languages",
			front: [{ name: "Python", icon: TbBrandPython }],
		},
		{
			title: language === "french" ? "LOGICIELS" : "SOFTWARE",
			front: [
				{ name: "Figma", icon: TbBrandFigma },
				{ name: "Wordpress", icon: FaWordpressSimple },
				{ name: "Webflow", icon: TbBrandWebflow },
			],
		},
	];

	return (
		<div
			className={
				(theme === "light" ? "text-black " : "text-white ") + "overflow-hidden"
			}
		>
			<div className="flex flex-col items-center mx-8 tablet:mx-[180px] z-10">
				<hr
					className={
						(theme === "light" ? "border-black" : "border-white") +
						" border-t w-4/5 mb-8 mt-[20px] z-10"
					}
				></hr>

				<h2 className="font-title text-2xl mb-16 z-10">
					{language === "french" ? "COMPETENCES" : " skills"}
				</h2>

				<div className="w-full flex flex-col desktop:flex-wrap desktop:flex-row items-center justify-between items-between z-10">
					{skills.map((category) => (
						<span
							className="flex flex-col items-center w-full desktop:w-6/12 mb-8 desktop:mb-16"
							key={category.key}
						>
							<h3 className="font-title text-xl">{category.title}</h3>
							<ul className="w-full tablet:w-2/3 flex justify-around font-title text-xs">
								{category.front.map((skill) => (
									<li className="flex flex-col items-center" key={skill.name}>
										<Icon component={skill.icon} />
										{skill.name}
									</li>
								))}
							</ul>
						</span>
					))}
				</div>
			</div>
			<span className="flex justify-between h-0">
				<div
					className={
						(theme === "light" ? " bg-gradient-light " : " bg-gradient-dark ") +
						"w-16 h-16 rounded-round relative bottom-52 left-48 z-0"
					}
				></div>
				<div
					className={
						(theme === "light" ? " bg-gradient-light " : " bg-gradient-dark ") +
						"w-48 h-48 rounded-round relative bottom-104 right-20 z-0"
					}
				></div>
			</span>
		</div>
	);
};

export default Skills;
