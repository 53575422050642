import { createContext, useEffect, useState } from "react";

export const LanguageContext = createContext();

function LanguageProvider({ children }) {
	const [language, setLanguage] = useState(
		localStorage.getItem("language") || "french"
	);

	const toggleLanguage = () => {
		setLanguage(language === "french" ? "english" : "french");
	};

	useEffect(() => {
		localStorage.setItem("language", language);
	}, [language]);

	return (
		<LanguageContext.Provider value={{ language, toggleLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
}

export default LanguageProvider;
