import React from "react";
import About from "../components/About";
import Projects from "../components/Projects";
import Section1 from "../components/Section1";
import Skills from "../components/Skills";
import Interrested from "../components/Interrested";

const Home = () => {
	return (
		<>
			<Section1 />
			<About />
			<Projects />
			<Skills />
			<Interrested />
		</>
	);
};

export default Home;
